import { TFunction } from 'react-i18next'
import { RequiredStringSchema } from 'yup/lib/string'
import { forms } from 'src/domain/translations'
import { nonEmptyStringSchema } from 'src/Flex/Shared/ui/Form'
import { dniValidate, isPassportFormatValid } from 'src/core/helpers'
import { documentType } from 'src/domain/enum'

export const documentSchema = (
  t: TFunction<'translation', undefined>
): RequiredStringSchema<string | undefined> =>
  nonEmptyStringSchema(t)
    .test('dniTestValidate', t(forms.errors.invalidFormat), (value, ctx) => {
      let isValid = true
      if (ctx.parent.documentType < documentType.Passport) {
        isValid = dniValidate(value)
      }
      if (ctx.parent.documentType === documentType.Passport && value) {
        return isPassportFormatValid(value);
      }
      return isValid
    })
    .max(14, t(forms.errors.maxAllowed) + ' ' + 14)
