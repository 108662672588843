import {
  OreButton,
  OreDivider,
  OreHeading,
  OreStack,
  OreText,
  SvgEdit,
} from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'

import { Trans, useTranslation } from 'react-i18next'
import {
  FlexPayrollList,
  PayrollSelector,
  PayrollsProvider,
} from 'src/Flex/Payrolls/ui/payroll-list'
import { flexPayrollsTranslations } from 'src/Flex/Payrolls/ui/translations'
import { useNavigate } from 'react-router-dom'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexPayrollsPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PageWrapper>
      {/* <OreStack direction="row" placeContent="space-between"> */}
      <OreHeading as="h1" size="title-md">
        {t(flexPayrollsTranslations.title)}
      </OreHeading>
      {/* <OreButton
          icon={<SvgEdit />}
          category="primary"
          size="small"
          onClick={() => navigate(flexNavigationRoutes.flexPayrollsDnD)}>
          {t(flexPayrollsTranslations.customPayroll.title)}
        </OreButton> */}
      {/* </OreStack> */}
      <OreDivider space="larger-bottom" />
      <PayrollsProvider>
        <OreStack space="large" placeContent="stretch">
          <OreStack>
            <OreText>
              <Trans t={t}>{t(flexPayrollsTranslations.subtitle)}</Trans>
            </OreText>
            <OreText>{t(flexPayrollsTranslations.disclaimer)}</OreText>
          </OreStack>
          <PayrollSelector />
          <FlexPayrollList />
          <VideoTutorial
            title={t(flexPayrollsTranslations.videoTutorial)}
            youtubeUrl="https://www.youtube.com/embed/rjMAvfAk3To?si=E3h9atTnVFYZtT0f"
          />
        </OreStack>
      </PayrollsProvider>
    </PageWrapper>
  )
}
