import {
  OreButton,
  OreDivider,
  OreHeading,
  OrePill,
  OreStack,
  OreText,
  SvgAlert,
  SvgArrowWidget,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { MessagesModel } from 'src/domain/models'
import { cardOrderTranslation } from 'src/domain/translations'
import { ErrorMessagesTable } from 'src/presentation/components/Edenred/errorMessagesTable/ErrorMessagesTable'
import { orderValidationsTranslations } from '../orderValidationsTranslations'

export const OrderValidationErrorStep = (props: {
  errors: MessagesModel[]
  excelError: string
  back: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <OreStack>
        <OreHeading size="headline-md">
          {t(orderValidationsTranslations.errorStep.title)}
        </OreHeading>
        <OreText>{t(orderValidationsTranslations.errorStep.description1)}</OreText>
        <OreText>{t(orderValidationsTranslations.errorStep.description2)}</OreText>
        <div className="whitespace-nowrap">
          <OrePill icon={<SvgAlert />} tone="error-600">
            {t(cardOrderTranslation.excelAlerts.table.errorLabel)}
          </OrePill>
        </div>
        <OreText>
          {t(orderValidationsTranslations.errorStep.description3)}
          {<b>{t(orderValidationsTranslations.errorStep.description3Bold)}</b>}
          {t(orderValidationsTranslations.errorStep.description4)}
        </OreText>
      </OreStack>
      <ErrorMessagesTable
        errorMessages={props.errors}
        back={() => true}
        backToHome={() => true}
        additionalDataExcel={props.excelError}
        inStep={true}
      />
      <OreDivider space="larger-top" />
      <OreStack direction="row" placeContent="space-between">
        <OreButton category="tertiary" icon={<SvgArrowWidget />} onClick={props.back}>
          {t(orderValidationsTranslations.step2.buttonBack)}
        </OreButton>
      </OreStack>
    </>
  )
}
